import './bootstrap';
import '../css/app.css';

// Import modules...
import {createApp, h} from 'vue';
import {createInertiaApp, Link} from '@inertiajs/inertia-vue3';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

/* From the FontAwesome Docs */
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon as Icon} from '@fortawesome/vue-fontawesome';

library.add(fab, fas, far);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Vendesmart';
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, app, props, plugin}) {
        return createApp({render: () => h(app, props)})
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueToast)
            .component('Link', Link)
            .component('icon', Icon)
            .mount(el);
    },
});

InertiaProgress.init({
    color: '#f9b688',
    showSpinner: true,
});
